// import Footer from "./components/footer/footer";
import Header from "../components/header/header";
import Link from "../components/link/link";
const Haris = () => {
  return (
    <>
      <Header />
      <Link />
      {/* <Footer /> */}
    </>
  );
};

export default Haris;
