import React from 'react';
const PdfView = () => {
    const pdfURL = 'https://www.holybooks.com/wp-content/uploads/2010/05/The-Holy-Bible-King-James-Version.pdf';
    return (
        <div>
            <p>Alternative text - include a link <a href="https://www.holybooks.com/wp-content/uploads/2010/05/The-Holy-Bible-King-James-Version.pdf" target="_blank">to the PDF!</a></p>

        </div>
    );
};
export default PdfView;