// import Footer from "./components/footer/footer";
import Header from "../components/header/header";
import Link from "../components/link/link";
import Whyus from "../components/whyus/whyus";
const Home = () => {
  return (
    <>
      <Header />
      <Whyus></Whyus>
    </>
  );
};

export default Home;
