const socialLinks = [
  "https://github.com/vigneshshettyin",
  "https://linkedin.com/in/vigneshshettyin",
  "https://twitter.com/vigneshshettyin",
  "https://instagram.com/vigneshshettyin",
  "https://facebook.com/vigneshshettyin",
];
const profileData = {
  userName: "vigneshshettyin",
  photoLink: "./header.png",
  desc: "Tech Geek | Mentor @ DeltaSJEC",
};

const themeData = {
  light: {
    backgroundColor: "white",
    onHoverBackgroundColor: "#f0f0f0",
    cardBackgroundColor: "#ffffff",
    onHoverTextColor: "#636e72",
    footerColor: "black",
    footerSocialLinkColor: "white",
    headerFontColor: "#black",
    CardtextColor: "#2d3436",
  },
  dark: {
    backgroundColor: "#000000",
    onHoverBackgroundColor: "#02040a",
    cardBackgroundColor: "#222222",
    onHoverTextColor: "white",
    footerColor: "white",
    footerSocialLinkColor: "white",
    headerFontColor: "white",
    CardtextColor: "white",
  },
};
const linkData = [{
  id: 1,
  link: "/",
  name: "Why Choose Us?",
  image:
    "https://github.com/vigneshshettyin.png",
},
{
  id: 1,
  link: "https://go.vigneshcodes.in/meetly",
  name: "Click here for Admission Registration",
  image:
    "https://github.com/vigneshshettyin.png",
},
{
  id: 2,
  link: "/pdf",
  name: "Fees Structure 2024-25",
  image:
    "https://play-lh.googleusercontent.com/QYc9QNf0_c-M7rhF4eo8daWbIRg_ukwzabR3Xj9TX_6zxG-AbzhKF5NS3CAd2X79fm0=s360-rw",
},
{
  id: 3,
  link: "https://eurl.tech",
  name: "Broucher 2024-25",
  image:
    "https://play-lh.googleusercontent.com/QYc9QNf0_c-M7rhF4eo8daWbIRg_ukwzabR3Xj9TX_6zxG-AbzhKF5NS3CAd2X79fm0=s360-rw",
},
{
  id: 4,
  link: "https://go.vigneshcodes.in/bulkmailer",
  name: "Campus Video Gallery",
  image:
    "https://play-lh.googleusercontent.com/QYc9QNf0_c-M7rhF4eo8daWbIRg_ukwzabR3Xj9TX_6zxG-AbzhKF5NS3CAd2X79fm0=s360-rw",
},
{
  id: 5,
  link: "https://tesla-clone-6820b.web.app",
  name: "Campus Photo Gallery",
  image:
    "https://play-lh.googleusercontent.com/QYc9QNf0_c-M7rhF4eo8daWbIRg_ukwzabR3Xj9TX_6zxG-AbzhKF5NS3CAd2X79fm0=s360-rw",
},
{
  id: 6,
  link: "https://web-scrapper-v2.herokuapp.com",
  name: "Know More about Scholarships",
  image:
    "https://play-lh.googleusercontent.com/QYc9QNf0_c-M7rhF4eo8daWbIRg_ukwzabR3Xj9TX_6zxG-AbzhKF5NS3CAd2X79fm0=s360-rw",
},
{
  id: 6,
  link: "https://web-scrapper-v2.herokuapp.com",
  name: "Accreditations and Rankings",
  image:
    "https://play-lh.googleusercontent.com/QYc9QNf0_c-M7rhF4eo8daWbIRg_ukwzabR3Xj9TX_6zxG-AbzhKF5NS3CAd2X79fm0=s360-rw",
}
];

export { socialLinks, profileData, linkData, themeData };
