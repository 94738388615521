import { Routes, Route} from "react-router-dom";
import Nebeel from "./pages/Nebeel";
import Aswin from "./pages/Aswin";
import Haris from "./pages/Haris";
import Subash from "./pages/Subash";
import Home from "./pages/Home";
import PdfView from "./components/pdfview/pdfview";
function App() {
  return (
    <div className="container">
      <Routes>
        <Route path="/" element={<Home />}>
        </Route>
          <Route path="/NBZ" element={<Nebeel />} />
          <Route path="/STK" element={<Subash />} />
          <Route path="/HRP" element={<Haris />} />
          <Route path="/AWN" element={<Aswin />} />
          <Route path="/pdf" element={<PdfView />} />
      </Routes>
    </div>
  );
}

export default App;